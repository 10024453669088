import React from 'react';
import '../styles/Policies.css';

const TermsOfService = () => {
  return (
    <div className="text-container">
      <div className="text-content">
        <h1>Terms of Service</h1>
        <p>Last Updated: 13/07/2024</p>
        <p>Welcome to Epoc!</p>
        <p>By accessing or using our website and services, you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any part of these Terms, you must not use our website or services.</p>
        <h2>1. Use of Services</h2>
        <p>1.1 You must be at least 13 years old to use our services.</p>
        <p>1.2 You agree to provide accurate and complete information when creating an account.</p>
        <p>1.3 You are responsible for maintaining the confidentiality of your account and password.</p>
        <h2>2. Payment and Refunds</h2>
        <p>2.1 Payments for reserving access to the Genesis archive are processed via Stripe.</p>
        <p>2.2 All payments are non-refundable except as required by law.</p>
        <h2>3. User Content</h2>
        <p>3.1 You retain ownership of the content you upload to Epoc.</p>
        <p>3.2 By uploading content, you grant Epoc a worldwide, non-exclusive, royalty-free license to use, reproduce, and display your content.</p>
        <h2>4. Prohibited Activities</h2>
        <p>4.1 You agree not to engage in any illegal or unauthorized activities while using our services.</p>
        <p>4.2 You must not interfere with or disrupt the service.</p>
        <h2>5. Termination</h2>
        <p>5.1 We reserve the right to terminate or suspend your account at any time for any reason.</p>
        <h2>6. Limitation of Liability</h2>
        <p>6.1 Epoc will not be liable for any indirect, incidental, or consequential damages arising from your use of our services.</p>
        <h2>7. Governing Law</h2>
        <p>7.1 These Terms are governed by and construed in accordance with the laws of The United Kingdom of Great Britain and Northern Ireland.</p>
        <h2>8. Changes to Terms</h2>
        <p>8.1 We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website.</p>
        <p>If you have any questions about these Terms, please contact us at ewan@epoc.life.</p>
      </div>
    </div>
  );
};

export default TermsOfService;
