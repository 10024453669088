import React, { useEffect } from 'react';

const preloadImages = (urls) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const preloadVideos = (urls) => {
  urls.forEach((url) => {
    const video = document.createElement('video');
    video.src = url;
    video.preload = 'auto';
  });
};

const PreLoad = ({ imageUrls, videoUrls }) => {
  useEffect(() => {
    if (imageUrls) preloadImages(imageUrls);
    if (videoUrls) preloadVideos(videoUrls);
  }, [imageUrls, videoUrls]);

  return null; // This component doesn't render anything
};

export default PreLoad;
