import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/EarlyAccess.css';
import File from '../Components/File.js';
import Archive from '../assets/Archive.png';
import { useSSRSafeId } from '@react-aria/ssr';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc } from 'firebase/firestore';
import { firestore, functions, analytics } from '../firebase-config'; // Make sure to provide the correct path
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ring } from 'ldrs';
import { getAnalytics, logEvent } from 'firebase/analytics';

const stripePromise = loadStripe('pk_live_51NikHhGoexn3QhCNsv6ENZhLQTbhqFI2tLkyU54xHUtfcM2u63kkVjQTqOdfML9ISxJ0KbfTz3s4S08ooeVUbYn7001QXNxwDk');

function EarlyAccess() {
    const location = useLocation();
    const { res } = location.state || {};

    const [waitlist, setWaitlist] = useState(false);
    const [reserve, setReserve] = useState(false);
    const [reserveProgress, setReserveProgress] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inputNameIsEmpty, setInputNameIsEmpty] = useState(false);
    const [inputEmailIsEmpty, setInputEmailIsEmpty] = useState(false);
    const [inputPasswordIsEmpty, setInputPasswordIsEmpty] = useState(false);
    const [incorrectPasswordFormat, setIncorrectPasswordFormat] = useState(false);
    const date = new Date();
    const auth = getAuth();
    const navigate = useNavigate();
    ring.register()
    const [submitted, setSubmitted] = useState(false);
    const [login, setLogin] = useState(false);
    const [waitlistJoined, setWaitlistJoined] = useState(false);
    const [tcChecked, setTcChecked] = useState(false);
    const functions = getFunctions();
    const analytics = getAnalytics();

    useEffect(() => {
        window.scrollTo(0, 0);
        const query = new URLSearchParams(window.location.search);

        if (res) {
            setReserve(true);
        }

        if (JSON.parse(localStorage.getItem('waitlist'))) {
            setWaitlistJoined(true)
        }

        const warmUpFunction = async () => {
            const addDocument1 = httpsCallable(functions, 'joinWaitlist');
            try {
                await addDocument1({ warmUp: true }); // Dummy call to warm up
            } catch (error) {
                console.error("Warm up error: ", error);
            }
            const addDocument2 = httpsCallable(functions, 'createStripeCheckout');
            try {
                await addDocument2({ warmUp: true }); // Dummy call to warm up
            } catch (error) {
                console.error("Warm up error: ", error);
            }
        };
        warmUpFunction();

    }, []);



    const onBack = () => {
        if (!waitlist && !reserve && !login) {
            window.scrollTo(0, 0);
            navigate('/');  // Navigate to /some-path if the condition is met
        }
        if (waitlist) {
            window.scrollTo(0, 0);
            setWaitlist(false);
        }
        if (reserve && reserveProgress == 0 && !res) {
            window.scrollTo(0, 0);
            setName('');
            setReserve(false);
        }
        if (reserve && reserveProgress == 0 && res) {
            window.scrollTo(0, 0);
            setName('');
            setReserve(false);
            navigate('/InitialArchive');
        }
        if (reserve && reserveProgress == 1) {
            window.scrollTo(0, 0);
            setName('');
            setEmail('');
            setPassword('');
            setInputEmailIsEmpty(false);
            setInputPasswordIsEmpty(false);
            setIncorrectPasswordFormat(false);
            setReserveProgress(0);
        }
        if (login) {
            window.scrollTo(0, 0);
            setLogin(false);
        }
    }


    const NameNext = () => {
        if (!name.trim()) {
            setInputNameIsEmpty(true); // Set the state to indicate input is empty
            window.scrollTo(0, 0); // Scroll to top if needed
            return; // Stop the function here if name is empty
        }
        setInputNameIsEmpty(false); // Reset state if input is not empty
        setReserveProgress(1); // Proceed with setting the progress or other actions
        window.scrollTo(0, 0);
    };

    const Reserve = async () => {
        if (!email.trim()) {
            setInputEmailIsEmpty(true); // Set the state to indicate input is empty
            if (!password.trim()) {
                setInputPasswordIsEmpty(true); // Set the state to indicate input is empty
                setIncorrectPasswordFormat(true);
                window.scrollTo(0, 0); // Scroll to top if needed
                return; // Stop the function here if name is empty
            }
            else {
                if (!validatePassword()) {
                    setIncorrectPasswordFormat(true);
                    setInputPasswordIsEmpty(true); // Set the state to indicate input is empty
                }
                else {
                    setIncorrectPasswordFormat(false);
                    setInputPasswordIsEmpty(false); // Set the state to indicate input is empty
                }
            }
            window.scrollTo(0, 0); // Scroll to top if needed
            return; // Stop the function here if name is empty
        }
        if (!password.trim()) {
            setInputPasswordIsEmpty(true); // Set the state to indicate input is empty
            setIncorrectPasswordFormat(true);
            if (!validateEmail()) {
                setInputEmailIsEmpty(true); // Set the state to indicate input is empty
            }
            else {
                setInputEmailIsEmpty(false); // Set the state to indicate input is empty
            }
            window.scrollTo(0, 0); // Scroll to top if needed
            return; // Stop the function here if name is empty
        }
        else {
            if (!validateEmail()) {
                setInputEmailIsEmpty(true); // Set the state to indicate input is empty
            }
            else {
                setInputEmailIsEmpty(false); // Set the state to indicate input is empty
            }
            if (validatePassword() && validateEmail()) {
                setSubmitted(true);
                onAuthStateChanged(auth, async (user) => {
                    if (!user) {
                        try {
                            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                            await setPersistence(auth, browserLocalPersistence);
                            const user = userCredential.user;
                            const userUID = user.uid; // Ensure this is defined
                            const token = localStorage.getItem('referrerToken');
                            // Firestore part 
                            const userDocRef = doc(firestore, 'users', userUID);
                            await setDoc(userDocRef, { name: name, date: new Date().toISOString(), phase: false, coupon: token });
                            console.log("User data saved in Firestore successfully.");

                            // Proceed with checkout
                            handleCheckout();
                        }
                        catch (error) {
                            setSubmitted(false);
                            console.error("Error in creating user or saving data:", error);
                        }
                    }
                    else {
                        try {
                            const userCredential = await signInWithEmailAndPassword(auth, email, password);
                            const newUser = userCredential.user;
                            const userUID = newUser.uid;
                            const userDocRef = doc(firestore, 'users', userUID);
                            const docSnap = await getDoc(userDocRef);

                            if (docSnap.exists()) {
                                const userData = docSnap.data();
                                if (!userData.transactionProcessed) {
                                    handleCheckout();
                                }
                                else {
                                    navigate('/account');
                                    setSubmitted(false);
                                }
                            }
                        }
                        catch (error) {
                            const deleteUser = httpsCallable(functions, 'deleteUser');
                            try {
                                const result = await deleteUser();
                                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                                await setPersistence(auth, browserLocalPersistence);
                                const user = userCredential.user;
                                const userUID = user.uid; // Ensure this is defined
                                // Firestore part
                                const userDocRef = doc(firestore, 'users', userUID);
                                await setDoc(userDocRef, { name: name, date: new Date().toISOString(), phase: false });
                                console.log("User data saved in Firestore successfully.");
                                // Proceed with checkout
                                handleCheckout();
                            } catch (error) {
                                console.error("Error deleting account:", error);
                                setSubmitted(false);
                            }
                        }
                    }
                });

            }


        }

    };

    const Login = async () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const newUser = userCredential.user;
                const userUID = newUser.uid;
                const userDocRef = doc(firestore, 'users', userUID);
                const docSnap = await getDoc(userDocRef);
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (!userData.transactionProcessed) {
                        setInputEmailIsEmpty(true);
                        setInputPasswordIsEmpty(true);
                        logEvent(analytics, 'button_click', {
                            page_title: 'Login_Failed',
                        });
                    }
                    else {
                        setInputEmailIsEmpty(false);
                        setInputPasswordIsEmpty(false);
                        logEvent(analytics, 'button_click', {
                            page_title: 'Login_Success',
                        });
                        navigate('/')
                    }
                }
            })
            .catch((error) => {
                setInputEmailIsEmpty(true);
                setInputPasswordIsEmpty(true);
            });

    }

    const getCountry = async () => {
        try {
            const response = await fetch('https://ipinfo.io/json?token=fdea27bfadf250');
            const data = await response.json();
            return data.country; // Returns something like 'US', 'GB', etc.
        } catch (error) {
            console.error('Error fetching country:', error);
            return 'US'; // Fallback to 'US' if there's an error
        }
    };

    
    const handleCheckout = async () => {
        const stripe = await stripePromise;
        const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');
        try {
            const userCountry = await getCountry(); // Fetch the user's country
            const result = await createStripeCheckout({ 
                email: email,
                country: userCountry,  // Pass the user's country to the cloud function
            });
            const { id } = result.data;
            // Redirect to Stripe Checkout
            setSubmitted(false);
            await stripe.redirectToCheckout({ sessionId: id });
            logEvent(analytics, 'button_click', {
                page_title: 'Checkout_Opened',
            });
        } catch (error) {
            console.error('Error invoking Stripe Checkout', error);
        }
    };


    const validatePassword = () => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[^A-Za-z0-9]/.test(password);
        const isValidLength = password.length >= 8;
        if (hasUpperCase && hasNumber && hasSpecialChar && isValidLength) {
            setIncorrectPasswordFormat(false);
            return (true);
        }
        else {
            setIncorrectPasswordFormat(true);
            setInputPasswordIsEmpty(true); // Set the state to indicate input is empty
            return (false);
        }
    };

    const validateEmail = () => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    }

    function joinWaitlist() {
        window.scrollTo(0, 0);
        if (validateEmail()) {
            setSubmitted(true);
            const addDocument = httpsCallable(functions, 'joinWaitlist');
            addDocument({ email: email })
                .then(result => {
                    localStorage.setItem('waitlist', JSON.stringify({ joined: true }));
                    setEmail('');
                    navigate('/successWaitlist', { state: { email: email } });
                    logEvent(analytics, 'button_click', {
                        page_title: 'Waitlist_Joined',
                    });
                    setSubmitted(false);
                })
                .catch(error => {
                    console.error("Error adding document: ", error);
                });
        }
    }

    return (
        <div>
            <div className="containerIA">
                <div className="top-bar">
                    <button className="back-button" onClick={onBack}>Back</button>
                    <div className="purp-circle"></div>
                </div>
                <div className='split'>
                    {!reserve && !waitlist && !login ? <div className="p1 p1EA">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Have Your Part In History</h2>
                                <h5 id='EAst1'>Convey your unique essence</h5>
                            </div>
                        </div>
                    </div> : null}
                    {reserve ? <div className="p1 p1ResEA">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Have Your Part In History</h2>
                                <h5 id='EAst1'>Convey your unique essence</h5>
                            </div>
                        </div>
                    </div> : null}
                    {waitlist ? <div className="p1 p1WEA">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Have Your Part In History</h2>
                                <h5 id='EAst1'>Convey your unique essence</h5>
                            </div>
                        </div>
                    </div> : null}
                    {login ? <div className="p1 p1LEA">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Have Your Part In History</h2>
                                <h5 id='EAst1'>Convey your unique essence</h5>
                            </div>
                        </div>
                    </div> : null}
                    <div className="p2">
                        <div className='p2EA'>
                            {!waitlist && !reserve && !login ? <div className='OptionsEAContainer'>
                                {waitlistJoined ? <p id='SelectOneEA'></p> : null}
                                {!waitlistJoined ? <p id='SelectOneEA'>Select One</p> : null}
                                {!waitlistJoined ? <div className='WaitlistEA' onClick={() => { setWaitlist(true); window.scrollTo({ top: 0, behavior: 'smooth' }); logEvent(analytics, 'button_click', { page_title: 'Waitlist_Selected', }); }}>
                                    <p id='WaitlistPlaceholderEA'>Join Waitlist -<span id='WaitlistPlaceholderSmallerEA'> Stay updated on launch</span></p>
                                </div> : null}
                                {waitlistJoined ? <div className='WaitlistEA'>
                                    <p id='WaitlistPlaceholderEA'>Waitlist Joined -<span id='WaitlistPlaceholderSmallerEA'> Welcome to the community.</span></p>
                                </div> : null}
                                <div className='PremiumEA' onClick={() => { setReserve(true); window.scrollTo(0, 0); logEvent(analytics, 'button_click', { page_title: 'Reserve_Selected', }); }}>
                                    <p id='LAEA'>Limited Availability</p>
                                    <p id='ReserveTitleEA'>Reserve A Place In The Genesis Archive</p>
                                    <p className='featuresEA'>
                                        <span>- Preserve your experiences and creations.</span>
                                        <span>- Guarentee your spot in our first archive.</span>
                                        <span>- Receive exclusive early access.</span>
                                    </p>
                                    <div className='actionButtEA'><p id='actionButtJEA'>Reserve</p></div>
                                </div>
                                <div className='loginEA' onClick={() => { window.scrollTo(0, 0); setLogin(true); }}><p>Already reserved access? Login</p></div>
                            </div> : null}
                            {waitlist ?
                                <div className='Reserve1WContainer'>
                                    <div className='HeaderEAContainer'>
                                        <p id='SelectOneEA'>Join Waitlist</p>
                                    </div>
                                    <input
                                        type="email"
                                        className="WaitlistInputEA"
                                        tabIndex="-1"
                                        placeholder="Enter Your Email"
                                        style={{
                                            fontSize: '1em',
                                            color: inputNameIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state
                                            borderColor: inputNameIsEmpty ? 'red' : 'default' // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}

                                    />
                                    {!submitted ?
                                        <button className='WaitlistJoin' onClick={joinWaitlist}>
                                            Join
                                        </button> : null}
                                    {submitted ? <div className='next2WRing' ><l-ring
                                        size="25"
                                        stroke="4"
                                        bg-opacity="0"
                                        speed="2"
                                        color="#6563FF"
                                    ></l-ring> </div> : null}
                                </div>
                                : null}
                            {reserve && reserveProgress == 0 ?
                                <div className='Reserve1EAContainer'>
                                    <div className='HeaderEAContainer'>
                                        <p id='SelectOneEA'>Create Account</p>
                                        {/*<p id='AdditionalTextEA'>£19.99</p>*/}
                                    </div>
                                    <input
                                        type="text"
                                        className="JoinEA-name"
                                        tabIndex="-1"
                                        placeholder="Enter Your Full Name"
                                        style={{
                                            fontSize: '1em',
                                            color: inputNameIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state
                                            borderColor: inputNameIsEmpty ? 'red' : 'default' // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            if (inputNameIsEmpty) setInputNameIsEmpty(false); // Reset the warning when user starts typing
                                        }}
                                        autocapitalize="words"
                                    />
                                    <button className='next1EA' onClick={NameNext}>
                                        Next
                                    </button>
                                </div>
                                : null}
                            {reserve && reserveProgress == 1 ?
                                <div className='Reserve1EAContainer'>
                                    <div className='HeaderEAContainer'>
                                        <p id='SelectOneEA'>Create Account</p>
                                    </div>
                                    <input
                                        type="email"
                                        className="JoinEA-email"
                                        tabIndex="-1"
                                        placeholder="Enter your email"
                                        style={{
                                            fontSize: '1em',
                                            color: inputEmailIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state

                                            borderColor: inputEmailIsEmpty ? 'red' : null // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setInputEmailIsEmpty(false); // Reset the warning when user starts typing
                                            setEmail(e.target.value)
                                        }} />
                                    <input
                                        type="password"
                                        className="JoinEA-password"
                                        placeholder="Create a password"
                                        style={{
                                            fontSize: '1em',
                                            color: inputPasswordIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state

                                            borderColor: inputPasswordIsEmpty ? 'red' : null // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            if (inputPasswordIsEmpty) setInputPasswordIsEmpty(false); // Reset the warning when user starts typing
                                        }} />
                                    <div>
                                        {incorrectPasswordFormat ? <div>
                                            <p id='reqTitle'>Password must contain:</p>
                                            <ul>
                                                <li>At least 8 characters</li>
                                                <li>At least one special character</li>
                                                <li>At least one number</li>
                                                <li>At least one uppercase letter</li>
                                            </ul>
                                        </div> : null}
                                        {!submitted ? <div className='createAccountButtcontainer'>
                                            <button className='next2EA' onClick={Reserve}>Join</button>
                                        </div> : null}
                                        {submitted ? <div className='next2EARing' ><l-ring
                                            size="25"
                                            stroke="4"
                                            bg-opacity="0"
                                            speed="2"
                                            color="#6563FF"
                                        ></l-ring></div> : null}
                                    </div>
                                </div>
                                : null}
                            {login ?
                                <div className='Reserve1EAContainer'>
                                    <div className='HeaderEAContainer'>
                                        <p id='SelectOneEA'>Sign In</p>
                                    </div>
                                    <input
                                        type="email"
                                        className="JoinEA-email"
                                        tabIndex="-1"
                                        placeholder="Enter your email"
                                        style={{
                                            fontSize: '1em',
                                            color: inputEmailIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state

                                            borderColor: inputEmailIsEmpty ? 'red' : null // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setInputEmailIsEmpty(false); // Reset the warning when user starts typing
                                            setEmail(e.target.value)
                                        }} />
                                    <input
                                        type="password"
                                        className="JoinEA-password"
                                        placeholder="Create a password"
                                        style={{
                                            fontSize: '1em',
                                            color: inputPasswordIsEmpty ? 'red' : 'black', // Change text color based on the inputIsEmpty state

                                            borderColor: inputPasswordIsEmpty ? 'red' : null // Optional: change border color
                                        }}
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            if (inputPasswordIsEmpty) setInputPasswordIsEmpty(false); // Reset the warning when user starts typing
                                        }} />
                                    <div>
                                        {!submitted ? <button className='next2EA' onClick={Login} style={{ textAlign: 'center' }}>Login</button> : null}
                                        {submitted ? <div className='next2EARing' ><l-ring
                                            size="25"
                                            stroke="4"
                                            bg-opacity="0"
                                            speed="2"
                                            color="#6563FF"
                                        ></l-ring></div> : null}
                                    </div>

                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </div>
            {reserve && reserveProgress == 1 ?
                <div className='tcWarning'>
                    <a href='#/terms'>By clicking Join you agree to our Terms and Conditions</a>
                </div>
                : null}
        </div>
    );
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#F3F3F3',
        borderColor: '#F3F3F3',
        borderRadius: '14px',
        borderWidth: '1px',
        width: '180px',
        cursor: 'pointer',
        padding: '8px 16px',
        boxSizing: 'border-box',
        height: '5vh',
    }),
    // You can add more style overrides here if needed
};

export default EarlyAccess;
