// Transition.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Transition = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsVisible(false);
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 0); // Trigger fade-in immediately

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <div className={`transition-container ${isVisible ? 'fade-in' : ''}`}>
      {children}
    </div>
  );
};

export default Transition;
