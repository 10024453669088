import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../firebase-config'; // Make sure this is the correct path and named export
import {loadStripe} from '@stripe/stripe-js';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const functions = getFunctions(app); // Pass the Firebase app instance
    const fetchSession = httpsCallable(functions, 'sessionStatus?session_id=${sessionId}');
    fetchSession()
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });

  }, []);


  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};

export default Return;
