import React from 'react';
import '../styles/Policies.css';

const CookiePolicy = () => {

  return (
    <div className="text-container">
      <div className="text-content">
        <h1>Cookie Policy</h1>
        <p>Last Updated: 13/07/2024</p>
        <p>Epoc ("we", "us", or "our") uses cookies on https://epoc.life/ (the "Site"). By using the Site, you consent to the use of cookies.</p>
        <h2>1. What Are Cookies?</h2>
        <p>Cookies are small text files stored on your device by a web browser that track, save, and store information about your interactions with our website. There are several types of cookies we use:</p>
        <ul>
          <li><strong>Session Cookies:</strong> These cookies are temporary and are deleted once you close your browser.</li>
          <li><strong>Persistent Cookies:</strong> These cookies remain on your device until they expire or you delete them.</li>
          <li><strong>First-Party Cookies:</strong> These are set by us to remember your preferences and settings.</li>
          <li><strong>Third-Party Cookies:</strong> These are set by other domains to track your activities across different websites.</li>
        </ul>
        <h2>2. How We Use Cookies</h2>
        <p>We use cookies for the following purposes:</p>
        <ul>
          <li><strong>Essential Cookies:</strong> Necessary for the operation of our website.</li>
          <li><strong>Performance Cookies:</strong> Help us understand how you use our website to improve its performance.</li>
          <li><strong>Functionality Cookies:</strong> Allow us to remember your preferences and provide enhanced features.</li>
          <li><strong>Targeting/Advertising Cookies:</strong> Used to deliver relevant ads and measure their effectiveness.</li>
        </ul>
        <h2>3. Managing Cookies</h2>
        <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. However, this may prevent you from taking full advantage of our website.</p>
        <p>For more information about cookies and how to manage them, please visit <a href="https://www.aboutcookies.org">aboutcookies.org</a>.</p>
        <h2>4. Third-Party Cookies</h2>
        <p>We may also use third-party cookies to help us analyze the use of our Site and to provide targeted advertising.</p>
        <p>If you have any questions about our use of cookies, please contact us at ewan@epoc.life.</p>
      </div>
    </div>
  );
};

export default CookiePolicy;
