import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/File.css';
import Archive from '../assets/Archive.png';
import Spline from '@splinetool/react-spline';

function File() {
    return (
        <div className={'file-container'}>
            <p id='aTitle'>Genesis Archive</p>
            <img src={Archive} id="archive" />
            <p id='a1'>We release exclusive Epoc archives that allow you to contribute and preserve your experiences, ideas, and creations, collectively capturing defined periods in time.</p>
                <p id='a2'>Learn more</p>
        </div>

    );
}

export default File;
