import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore, functions, analytics } from '../firebase-config';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../styles/Success.css';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ReactPixel from 'react-facebook-pixel';

function SuccessPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const verifyStripeSession = httpsCallable(functions, 'verifyStripeSession');
    const [transacted, setTransacted] = useState(false);
    const auth = getAuth();
    const user = auth.currentUser;
    const userUID = user ? user.uid : null;
    const [submitted, setSubmitted] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [isPending, setIsPending] = useState(true); // State to track if the transaction is being processed
    const analytics = getAnalytics();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');
        if (!sessionId) {
            navigate('/');
            return;
        }

        if (!transacted) {
            verifyStripeSession({ sessionId })
                .then(({ data }) => {
                    if (data.status) {
                        setTransacted(true);
                    } else {
                        navigate('/'); // Redirect if payment verification is unsuccessful
                    }
                })
                .catch(error => {
                    console.error('Verification failed', error);
                    navigate('/');
                });
        }

        ReactPixel.track('Purchase', {
            value: 19.99,
          });

    }, [transacted, navigate, location.search, verifyStripeSession]);

    useEffect(() => {
        if (transacted) {
            TransactionSuccess();
        }
    }, [transacted]); // Dependency on transacted state

    const submitFeedback = async () => {
        try {
            setSubmitted(true);
            const userDocRef = doc(firestore, 'feedback', userUID);
            await setDoc(userDocRef, { user: userUID, feedback: feedback });
            console.log("User data saved in Firestore successfully.");
        } catch (error) {
            console.error("Error in creating user or saving data:", error);
        }
    }

    const TransactionSuccess = async () => {
        const recordTransactionSuccess = httpsCallable(functions, 'recordTransactionSuccess');
        setIsPending(true);

        try {
            const result = await recordTransactionSuccess();
            logEvent(analytics, 'page_view', {
                page_title: 'Transaction_Success',
            });
            console.log(result.data.result);  // "Transaction data saved successfully"
        } catch (error) {
            console.error('Error recording transaction success', error);
        }
        finally {
            setIsPending(false); // Clear the pending state after the transaction is done
        }
    }

    return (
        <div className="containerIA">
            <div className="top-bar">
                {!isPending ? <Link to="/">
                    <button className="back-button" >Home</button>
                </Link> : null}
                {isPending ? <div>
                    <button className="back-button" >Home</button>
                </div> : null}
                <div className="purp-circle"></div>
            </div>
            <div style={{ marginTop: '0px' }}>
                <div className='split'>
                    <div className="p1">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Payment Successful</h2>
                                <h4 id='EAst1' style={{ fontSize: '15px' }}>Access to the Epoc Genesis Archive Reserved</h4>
                            </div>
                        </div>
                    </div>
                    <div className="p2">
                        <div className='p2EA'>
                            <div className='OptionsEAContainer'>
                                {!submitted ? <div className='PremiumEA'>
                                    <textarea
                                        className="feedbackInput"
                                        tabIndex="-1"
                                        placeholder="Ask us something or give us some feedback, we’d love to hear from you."
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        autocapitalize="sentences"
                                        style={{
                                            fontSize: '16px',
                                            resize: 'none' // Disables resizing of the textarea
                                        }}
                                        onChange={(e) => {
                                            setFeedback(e.target.value);
                                        }}
                                    ></textarea>
                                    <button className='next2Suc' onClick={submitFeedback}>Submit</button>
                                </div> : null}
                                {submitted ? <div>
                                    <p id='ThankYou'>Thank you for your feedback.</p>
                                </div> : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='viewAccSucContainer'>
                {!isPending ? <Link to="/account">
                    <button id='ViewAccSuc' >View Account</button>
                </Link> : null}
                {isPending ? <div>
                    <button id='ViewAccSuc' >View Account</button>
                </div> : null}
            </div>
        </div >
    );
}

export default SuccessPage;
