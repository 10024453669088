import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { functions } from '../firebase-config'; // Import your Firebase config here

const stripePromise = loadStripe('pk_live_51NikHhGoexn3QhCNsv6ENZhLQTbhqFI2tLkyU54xHUtfcM2u63kkVjQTqOdfML9ISxJ0KbfTz3s4S08ooeVUbYn7001QXNxwDk');

function CheckoutButton() {
  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');

    createStripeCheckout().then((result) => {
      // Retrieve session ID from Firebase function
      const { id } = result.data;
      const successUrl = 'https://example.com/success?session_id=' + id;
      // Redirect to Stripe Checkout
      stripe.redirectToCheckout({ sessionId: id, successUrl });
    }).catch(error => {
      console.error('Error invoking Stripe Checkout', error);
    });
  };

  return <button onClick={handleCheckout}>Checkout</button>;
}

export default CheckoutButton;
