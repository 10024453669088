import { initializeApp } from 'firebase/app';
import { initializeAuth, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions'; // Import getFunctions
import { getAnalytics } from 'firebase/analytics';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBIeLanRQy1JiMbjGzZen3vvs4hyMiAHXU",
    authDomain: "epoc-prerelease.firebaseapp.com",
    projectId: "epoc-prerelease",
    storageBucket: "epoc-prerelease.appspot.com",
    messagingSenderId: "659181049185",
    appId: "1:659181049185:web:703749fd472a8d74c16120",
    measurementId: "G-C87YRQR5HP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = initializeAuth(app, {
    persistence: browserLocalPersistence
});

// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Firebase Functions
const functions = getFunctions(app); // Initialize functions using the Firebase app instance

const analytics = getAnalytics(app);

export { app, auth, firestore, functions, analytics }; // Export the initialized Firebase services
