import React from 'react';
import '../styles/Footer.css'; // Link to the CSS file for styling
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <a>© Epoc</a>
        <a href='#/cookies'>Cookies</a>
        <a href='#/terms'>Terms</a>
        <a href='#/privacy'>Privacy</a>
      </div>
    </footer>
  );
}

export default Footer;
