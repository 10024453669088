import React, { useState, useEffect } from 'react';
import '../styles/Typewriter.css';

// Typewriter component
const Typewriter = ({  text, speed, onComplete, className }) => {
    const [index, setIndex] = React.useState(0);
    
    React.useEffect(() => {
        if (index < text.length) {
          const timeoutId = setTimeout(() => setIndex(index + 1), speed);
          return () => clearTimeout(timeoutId);
        } else if (index === text.length) {
            setTimeout(() => {
                onComplete();
              }, 1000);
        }
      }, [index, text.length, speed, onComplete]);
  
    return (
      <div className={className} style={{ maxWidth: '300px', whiteSpace: 'normal', fontSize: '14px', fontWeight: '500' }}>
        {text.substring(0, index)}
        <span className="cursor" style={{ backgroundColor: index < text.length ? 'black' : 'transparent', fontSize: '13px' }}>|</span>
      </div>
    );
  };
  

export default Typewriter;
