import React, { useState, useEffect, useMemo } from 'react';
import '../styles/Account.css';
import Archive from '../assets/Archive.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot, query, orderBy, getDocs, limit, updateDoc, where, writeBatch } from 'firebase/firestore';
import { auth, firestore, fireStorage, functions } from '../firebase-config';
import { getFunctions, httpsCallable } from "firebase/functions";


function Account() {
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    const userUID = user.uid;
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [settings, setSettings] = useState(false);

    const onBack = () => {
        navigate('/');
    }

    useEffect(() => {
        const checkUserTransaction = async () => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const userDocRef = doc(firestore, 'users', user.uid);
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        if (!userData.transactionProcessed) {
                            const userDocRef = doc(firestore, 'users', userUID);
                            await updateDoc(userDocRef, { transactionProcessed: true });
                        }
                        setName(userData.name);
                        const formattedDate = formatDate(userData.date);
                        setDate(formattedDate);
                        console.log(userData);
                    }
                }
            });
        };
        checkUserTransaction();
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }


    const handleLogout = async () => {
        try {
            await signOut(auth); // Sign out the user
            console.log("User signed out successfully!");
            navigate('/');
            // Additional logic after successful logout (e.g., redirecting)
        } catch (error) {
            console.error("Error signing out:", error);
            // Handle errors here, such as a failed logout attempt
        }
    };

    const handleDeleteAccount = async () => {
        const functions = getFunctions();
        const deleteUser = httpsCallable(functions, 'deleteUser');
        try {
            const result = await deleteUser();
            const auth = getAuth();
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error("Error deleting account:", error);
            // Handle errors here, such as showing an error message to the user
        }
    };


    return (
        <div className=''>
            <div className="top-bar">
                <button className="back-button" onClick={onBack}>Back</button>
                <div className="purp-circle"></div>
            </div>
            <h1 id="NameAcc">{name}</h1>
            <div className='subContainerAcc'>
                <h3 id="dateJoinedAcc">Joined: {date}</h3>
                {!settings ? <button id="settingsAcc" onClick={() => { setSettings(true) }}>Settings</button> : null}
                {settings ? <button id="settingsAcc" onClick={() => { setSettings(false) }}>Close</button> : null}
            </div>
            {settings ?
                <div className='settingsContainer'>
                    <button id='singOut' onClick={handleLogout}>Sign out</button>
                    <button id='delete' onClick={handleDeleteAccount}>Delete Account</button>
                </div>
                : null}
            <div className='ReservationsAcc'>
                <div className='ReservationsTitleAcc'>
                    <p>Accessible Archives</p>
                </div>
                <div className='ArchiveDetailsAcc'>
                    <div className='ArchiveItemAcc'>
                        <p className='ArchiveTitleAcc'>Genesis Archive</p>
                        <img src={Archive} id="archiveAcc" alt="Genesis Archive" />
                    </div>
                    <div className='ArchiveDescriptionAcc'>
                        <p id='descriptionAcc1'>This Archive will be accessible when the Epoc platform is released.</p>
                        <p id='descriptionAcc1'>Launching 2024</p>
                    </div>
                </div>
                <p className='ReservedStatusAcc'>Reserved</p>
            </div>
        </div>
    );
}

export default Account;
