import React, { useEffect, useRef, useState } from 'react';
import Typewriter from '../Components/Typewriter';
import '../styles/Animation.css';

const Animation = ({ onComplete }) => {
    const videoRef = useRef(null);
    const [typewriterDone, setTypewriterDone] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showTypewriter, setShowTypewriter] = useState(false); // Start true to always render initially
    const [vidFinished, setVidFinished] = useState(false);

    const isMacSafari = navigator.platform.startsWith('Mac') && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    useEffect(() => {
        const hasSeenTypewriter = sessionStorage.getItem('hasSeenTypewriter');
        if (!hasSeenTypewriter) {
            setShowTypewriter(true); // Always show initially
            //onComplete(); // Skip video for macOS Safari
        }
        else {
            if (!isMacSafari) {
                 setShowTypewriter(false);
                setShowVideo(true);
                playVideo();
            }else{
                onComplete();
            }
        }
    }, [isMacSafari]);

    useEffect(() => {
        if (typewriterDone) {
            sessionStorage.setItem('hasSeenTypewriter', true);
            setTimeout(() => {
                setShowTypewriter(false); // Wait for fade-out before hiding
                setTimeout(onComplete, 100); // Ensure all animations have completed
            }, 1000); // Wait 1 second for the fade-out animation to complete
        }
    }, [typewriterDone, isMacSafari]);

    const playVideo = () => {
        if (videoRef.current) {
            const videoElement = videoRef.current;
            videoElement.playbackRate = 2.0;
            videoElement.play().catch(error => {
                console.error('Error attempting to play video:', error);
            });

            setTimeout(() => {
                setVidFinished(true); // Trigger fade out
                setTimeout(onComplete, 1000); // After fade-out completes
            }, 3000);
        }
    };

    const skipTypewriter = () => {
        if(!typewriterDone){
            setTypewriterDone(true);
        }
    }

    const videoClass = showVideo ? (vidFinished ? 'fade-out' : 'fade-in') : 'hide-video';
    const typewriterClass = showTypewriter ? (typewriterDone ? 'fade-out' : 'fade-in') : 'hide-video';

    return (
        <div style={styles.container}>
            <div className="purple-circle"></div>

            <div className={typewriterClass} onClick={skipTypewriter}>
                <Typewriter
                    text="After we pass, the valuable experiences and ideas we've accumulated risk being forgotten, leading to the permanent loss of our essence."
                    speed={60}
                    onComplete={() => {
                        setTypewriterDone(true); // Start fade-out
                    }}
                />
            </div>

            <div className={videoClass}>
                <video id="animatedVideo" loop playsInline autoPlay muted ref={videoRef} style={{ height: '140px', width: '140px' }}>
                    <source src={require("../assets/epocLoad.mp4")} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
    },
};

export default Animation;
