import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/AppInisght.css';
import File from '../Components/File.js'
import EpocCard from '../assets/EpocCard.jpg';
import ArcScreen from '../assets/ArchScreen.png';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot, query, orderBy, getDocs, limit, updateDoc, where, writeBatch } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { auth, firestore, fireStorage, analytics } from '../firebase-config';

function InitalArchive() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [reserved, setReserved] = useState(false);
    const [options, setOptions] = useState(false);
    const analytics = getAnalytics();

    useEffect(() => {
        const onLoad = async () => {
            logEvent(analytics, 'page_view', {
                page_title: 'AppInisght',
            });
        }
        onLoad();
    }, []);

    return (
        <div className="containerIA">
            <div className="top-bar">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <button className="back-button">Back</button>
                </Link>
                <div className="purp-circle"></div>
            </div>
            <div className='split' style={{ marginTop: '0px' }}>
                <div className="p1">
                    <div className='detailsAI'>
                        <div className='detailsBlockAI'>
                            <div className='genArchDescriptionContainer'>
                                <div className='genResContainerAI'>
                                    <p id='genResTitle'>Inside The App</p>
                                </div>
                                <p id='subTitleAI'>Explore the key features and core functionalities.</p>
                                {options ? <div className='buttonsAI'>
                                    <button id='bf' onClick={() => { setOptions(false) }}>Collections</button>
                                    <button id='bt' >Archives</button>
                                </div> : null}
                                {!options ? <div className='buttonsAI'>
                                    <button id='bt'>Collections</button>
                                    <button id='bf' onClick={() => { setOptions(true) }}>Archives</button>
                                </div> : null}
                                {options ? <p id='genArchDescriptionAIDesktop'>
                                    Archives serve as repositories for our Collections, ensuring that our experiences, ideas, and creations endure over time. Each archive represents a unique period, but users are free to upload content from any point in time, providing a flexible way to preserve and showcase our diverse experiences, ideas, and creations.
                                    <br />
                                    <br />
                                    Archives operate in one of three states:
                                    <br />
                                    <br />
                                    Pre-Release: During this phase, users can reserve access to an upcoming archive, ensuring they have the opportunity to engage with its content once it becomes active.
                                    <br />
                                    <br />
                                    Active: In this state, the archive is open for contributions. Users can actively add their experiences, ideas, and creations, becoming a part of the collective narrative of that archive.
                                    <br />
                                    <br />
                                    Locked: Once an archive is locked, contributions are no longer possible, but the archive remains accessible for viewing. This allows users to explore and reflect on the preserved experiences and creations.
                                    <br />
                                    <br />
                                </p> : null}
                                {!options ? <p id='genArchDescriptionAIDesktop'>
                                    A Collection represents an experience, idea, or creation. Collections can include images, videos, text, and music, enabling you to capture and showcase your experiences to their fullest potential.
                                    <br />
                                    <br />
                                    Each user will receive a virtual Epoc card, which acts as a storage device for their collections. This card allows users to collate and organize their experiences, ideas, and creations seamlessly.
                                </p> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p2">
                    {options ?
                        <div className='ImgContainerAI'>
                            <div className='ImgContainerAISize'>
                                <img src={ArcScreen} id="imageAI" />
                            </div>
                            <div className='descImgAI'>
                                <p id='genArchDescriptionAIPhoneArch'>
                                Archives serve as repositories for our Collections, ensuring that our experiences, ideas, and creations endure over time. Each archive represents a unique period, but users are free to upload content from any point in time, providing a flexible way to preserve and showcase our diverse experiences, ideas, and creations.
                                    <br />
                                    <br />
                                    Archives operate in one of three states:
                                    <br />
                                    <br />
                                    Pre-Release: During this phase, users can reserve access to an upcoming archive, ensuring they have the opportunity to engage with its content once it becomes active.
                                    <br />
                                    <br />
                                    Active: In this state, the archive is open for contributions. Users can actively add their experiences, ideas, and creations, becoming a part of the collective narrative of that archive.
                                    <br />
                                    <br />
                                    Locked: Once an archive is locked, contributions are no longer possible, but the archive remains accessible for viewing. This allows users to explore and reflect on the preserved experiences and creations.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div> : null}
                    {!options ?
                        <div className='ImgContainerAI'>
                            <div className='ImgContainerAISize'>
                                <img src={EpocCard} id="imageAI" />
                            </div>
                            <div className='descImgAI'>
                                <p id='genArchDescriptionAIPhone'>
                                    A Collection represents an experience, idea, or creation. Collections can include images, videos, text, and music, enabling you to capture and showcase your experiences to their fullest potential.
                                    <br />
                                    <br />
                                    Each user will receive a virtual Epoc card, which acts as a storage device for their collections. This card allows users to collate and organize their experiences, ideas, and creations seamlessly.
                                </p>
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    );
}

export default InitalArchive;
