import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import '../styles/Home.css';
import File from '../Components/File.js'
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { auth } from '../firebase-config'; // Make sure to provide the correct path
import Logo from '../assets/logo.png';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firestore, functions, analytics } from '../firebase-config'; // Make sure to provide the correct path

function Home() {
    const [a, setA] = useState(false);
    const [b, setB] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [waitlistJoined, setWaitlistJoined] = useState(false);
    const auth = getAuth();
    const analytics = getAnalytics();
    const navigate = useNavigate();
    const location = useLocation();
    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {
        const checkUserTransaction = async () => {
            logEvent(analytics, 'page_view', {
                page_title: 'Home',
            });

            if (JSON.parse(localStorage.getItem('waitlist'))) {
                setWaitlistJoined(true);
            }

            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const userDocRef = doc(firestore, 'users', user.uid);
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        if (!userData.transactionProcessed && userData.phase) {
                            /*const deleteUser = httpsCallable(functions, 'deleteUser');
                            try {
                                const result = await deleteUser();
                            } catch (error) {
                                console.error("Error deleting account:", error);
                                // Handle errors here, such as showing an error message to the user
                            }*/
                        }
                        else if (!userData.transactionProcessed && !userData.phase) {
                            const userUID = user.uid; // Ensure this is defined
                            const userDocRef = doc(firestore, 'users', userUID);
                            await updateDoc(userDocRef, { phase: true });
                        }
                        else if (userData.transactionProcessed && userData.phase) {
                            setAuthenticated(true);
                        }
                    }
                }
            });
        };
        checkUserTransaction();
    }, [ location.search]);

    const toggle1 = async () => {
        setA(!a);
    }
    const toggle2 = async () => {
        setB(!b);
    }

    const handleLogout = async () => {
        try {
            await signOut(auth); // Sign out the user
            console.log("User signed out successfully!");
            // Additional logic after successful logout (e.g., redirecting)
        } catch (error) {
            console.error("Error signing out:", error);
            // Handle errors here, such as a failed logout attempt
        }
    };


    const loadAppInisght = async () => {
        window.scrollTo(0, 0);
        navigate('/appInsight');
    };
    return (
        <div className="container">
            <div className="purple-circle"></div>
            <h1 id="title">Epoc</h1>
            <h3 id="subTitle">A platform for you to preserve your experiences, ideas and creations, ensuring your place in history.</h3>
            <div className='split' style={{ display: 'flex', }}>
                <div className="p2">
                    <div className="f-container" >
                        <Link to="/InitialArchive" style={{ textDecoration: 'none' }}>
                            <div onClick={() => { window.scrollTo(0, 0); }}> <File /></div>
                        </Link>
                    </div>
                </div>
                <div className='vline'></div>
                <div className='hline'></div>
                <div className="p1" style={{}}>
                    <div className='appPreview'>
                        <div className='logoContianer' onClick={loadAppInisght}>
                            <img src={Logo} id="appIcon" />
                        </div>
                        <div className='appPreviewText' onClick={loadAppInisght}>
                            <p id='APTTitle'>Epoc App</p>
                            <p id='APTSTitle'>Documenting the experiences of Humanity, coming to you in 2024.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Join'>
                {!authenticated && !waitlistJoined ? <div className='waitlist'>
                    <Link to="/ReserveAccess">
                        <button id='EA' onClick={() => {
                            window.scrollTo(0, 0);
                            logEvent(analytics, 'button_click', {
                                page_title: 'Join_Home',
                            });
                        }}
                        >/Join/</button>
                    </Link>
                </div> : null}
                {authenticated ? <div className='waitlist'>
                    <Link to="/account">
                        <button id='EA' onClick={() => { window.scrollTo(0, 0); }}>/Account/</button>
                    </Link>
                </div> : null}
                {!authenticated && waitlistJoined ? <div className='waitlist'>
                <Link to="/ReserveAccess">
                        <button id='EA' onClick={() => {
                            window.scrollTo(0, 0);
                            logEvent(analytics, 'button_click', {
                                page_title: 'Join_Home',
                            });
                        }}
                        >/Reserve/</button>
                    </Link>
                </div> : null}

            </div>
        </div>
    );
}

export default Home;


/*
<div className="buttons">
                        <button className="btn" onClick={toggle1}><p id='btnTxt'>Vision</p></button>
                        {a ?
                            <p className='more'>
                                <span>- Immortalise our contributions and unique essences.</span>
                                <span>- Allow a part of us to live on with humanity.</span>
                                <span>- Preserve stories that would otherwise be lost in time.</span>
                            </p>

                            : null}
                        <button className="btn" onClick={toggle2}><p id='btnTxt'>Implementation</p></button>
                        {b ?
                            <p className='more'>
                                <span>- Create a mobile platform designed to document the journey of humanity. Allowing us to capture and preserve our unique essences.</span>
                                <span>- Users can create collections which act as representations of an experience or a creation.</span>
                                <span>- They can then upload these collections to the available preservation archive's.</span>
                            </p>
                            : null}
                    </div>
*/