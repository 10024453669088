import React from 'react';
import '../styles/Policies.css';

const PrivacyPolicy = () => {
  return (
    <div className="text-container">
      <div className="text-content">
        <h1>Privacy Policy</h1>
        <p>Last Updated: 13/07/2024</p>
        <p>Epoc ("we", "us", or "our") operates [Your Website URL] (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Site and services.</p>
        <h2>1. Information Collection and Use</h2>
        <p>1.1 We collect personal information such as your name, email address, and payment information.</p>
        <p>1.2 We use this information to provide and improve our services, process transactions, and communicate with you.</p>
        <h2>2. Cookies</h2>
        <p>2.1 We use cookies to collect information about your usage of our Site.</p>
        <p>2.2 You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
        <h2>3. Data Security</h2>
        <p>3.1 We implement security measures to protect your personal information.</p>
        <p>3.2 However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>
        <h2>4. Third-Party Services</h2>
        <p>4.1 We may employ third-party companies and individuals to facilitate our services (e.g., payment processors).</p>
        <p>4.2 These third parties have access to your personal information only to perform these tasks on our behalf.</p>
        <h2>5. Children's Privacy</h2>
        <p>5.1 Our services are not intended for use by anyone under the age of 13.</p>
        <p>5.2 We do not knowingly collect personal information from children under 13.</p>
        <h2>6. Changes to This Privacy Policy</h2>
        <p>6.1 We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Site.</p>
        <p>If you have any questions about this Privacy Policy, please contact us at ewan@epoc.life.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
