import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore, functions } from '../firebase-config';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../styles/SuccessWaitlist.css';
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';


function SuccessPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const [submitted, setSubmitted] = useState(false);
    const [submittedButt, setSubmittedButt] = useState(false);
    const [feedback, setFeedback] = useState('');


    useEffect(() => {
        window.scrollTo(0, 0);
        const warmUpFunction = async () => {
            const addDocument = httpsCallable(functions, 'waitlistFeedback');
            try {
                await addDocument({ warmUp: true }); // Dummy call to warm up
            } catch (error) {
                console.error("Warm up error: ", error);
            }
        };
        warmUpFunction();

        ReactPixel.track('Waitlist', {
            email: email,
          });
        
    }, []);

    const submitFeedback = async () => {
        try {
            console.log(email)
            setSubmittedButt(true);
            const addDocument = httpsCallable(functions, 'waitlistFeedback');
            addDocument({ email: email, feedback: feedback })
                .then(result => {
                    setSubmitted(true);
                    setSubmittedButt(false);
                })
                .catch(error => {
                    console.error("Error adding document: ", error);
                });
        } catch (error) {
            console.error("Error in creating user or saving data:", error);
        }
    }

    return (
        <div className="containerIA">
            <div className="top-bar">
                <Link to="/">
                    <button className="back-button" >Home</button>
                </Link>
                <div className="purp-circle"></div>
            </div>
            <div style={{ marginTop: '0px' }}>
                <div className='split'>
                    <div className="p1 p1WF">
                        <div className='TitleEA'>
                            <div className='TitleEAContainer'>
                                <h2 id='EAt1'>Successfully Joined Waitlist</h2>
                                <h4 id='EAst1' style={{ fontSize: '15px' }}>Welcome to the Epoc community.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="p2">
                        <div className='p2EA'>
                            <div className='OptionsEAContainer'>
                                {!submitted ? <div className='PremiumEA'>
                                    <textarea
                                        className="feedbackInput"
                                        tabIndex="-1"
                                        placeholder="Ask us something or give us some feedback, we’d love to hear from you."
                                        onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        autocapitalize="sentences"
                                        style={{
                                            fontSize: '16px',
                                            resize: 'none' // Disables resizing of the textarea
                                        }}
                                        onChange={(e) => {
                                            setFeedback(e.target.value);
                                        }}
                                    ></textarea>
                                    {!submittedButt ? <button className='next2Suc' onClick={submitFeedback}>Submit</button> :null}
                                    {submittedButt ? <div className='next2WFRing' ><l-ring
                                        size="25"
                                        stroke="4"
                                        bg-opacity="0"
                                        speed="2"
                                        color="#6563FF"
                                    ></l-ring> </div> : null}
                                </div> : null}
                                {submitted ? <div>
                                    <p id='ThankYou'>Thank you for your feedback.</p>
                                </div> : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SuccessPage;
